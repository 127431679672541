import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const LearnMore = () => {
  useDocTitle('Marquee Commodity Group');
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <h1 className="text-3xl  primary-text font-bold text-center ">
            Merchant Prince Corporate Vision.
          </h1>
          <div>
            <p className="my-3 text-xl text-gray-600 font-semibold m-5">
              Providing the best service and products to our customers; by
              ensuring strict quality controls and compliance with all import
              and export standardsat the leading competitive costs.
            </p>
          </div>
          <div>
            <p className="my-3 text-xl text-gray-600 font-semibold m-5">
              Therefore, allowing a superior consumer productat a cost
              competitive price to our clients; and ensuring an ongoing positive
              strategic stakeholder relationships.
            </p>
          </div>

          <ul className="my-3 text-xl primary-text font-semibold list-disc m-10">
            <li key={1}>Strict Quality Standards</li>
            <li key={2}>Leading Competitive Costs</li>
            <li key={3}>Superior Products</li>
            <li key={4}>Positive Stakeholder Relationships</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LearnMore;
