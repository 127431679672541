import React from 'react';
import Clients from '../components/Clients';
import ReadyToOrder from '../components/ReadyToOrder';
import Footer from '../components/Footer';
import Header from '../components/Header';
import About from '../components/About';
import AgricultureProducts from '../components/AgricultureProducts';
import IndustrialProducts from '../components/IndustrialProducts';

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <AgricultureProducts />
      <IndustrialProducts />
      <ReadyToOrder />
      <Footer />
    </>
  );
};

export default Home;
