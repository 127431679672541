import React from 'react';
import { Link } from 'react-router-dom';
import ashphalt from '../images/ashphalt.png';
import lumber from '../images/lumber.png';

const productData = [
  {
    name: `Ashphalt & Aggregates`,
    image: ashphalt,
    description: `Asphalt is a composite material consisting of aggregates, binder, and filler, widely used for building and maintaining various infrastructure projects, including roads, parking lots, railway tracks, ports, airport runways, bicycle lanes, sidewalks, and recreational areas like playgrounds and sports facilities.  \n 
    The aggregates used in asphalt mixtures can vary, but common examples include crushed rock, sand, gravel, and slags.\n
    Ashphalt & Aggregates are sold by the Metric Tonnes.\n`,
    details: [
      { label: 'Gross weight: ', value: 'Per MT (MOQ 2,500 MT)' },
    ],
  },
  {
    name: `BC Canada Softwood  Lumber Products`,
    image: lumber,
    description: `The coastal forest region of British Columbia, Canada spans 16.5 million hectares and boasts five commercially viable coniferous species, each with distinct properties.  \n 
    Wooden structures that have withstood the test of time to modern skyscrapers pushing the heights of engineering, wood has proven itself to be a robust and durable building material. Its strength and versatility make it an ideal choice for wood frame construction projects, including six-story buildings. \n
    BC's softwood lumber products are highly sought after in both North American and international markets. \n
    Contact us via email for inquiries. \n`,
    details: [],
  },
];

const Product = ({ name, image, description, details }) => {
  return (
    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
      <div className="m-2 text-justify text-sm flex flex-col justify-between h-full">
        <div>
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">{name}</h4>
          <img src={image} alt={name} />
          <div className={` ${description.length === 0  ?' mt-0'  : ' mt-5'} `}>
            <p>
              {description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            </div>
          <div className="py-5">
            {details.map((detail, index) => (
              <p key={index}>
                <span className="font-black">{detail.label}:</span> {detail.value}
              </p>
            ))}
          </div>
        </div>
        <PriceRequestButton />
      </div>
    </div>
  );
};

const PriceRequestButton = () => {
  return (
    <div className="flex justify-center my-1">
      <Link
        to="/request-price"
        className="text-white bg-[#4695CE] hover:bg-[#53B18D] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
      >
        Request price
        <svg
          className="w-4 h-4 ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </Link>
    </div>
  );
};

const Products = () => {
  return (
    <>
      <div className="my-4 py-4" id="products">
        <h2 className="my-2 text-center text-3xl primary-text uppercase font-bold">
          Industrial Products
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-[#4695CE] mb-8"></div>
        </div>
        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {productData.map((product, index) => (
              <Product key={index} name={product.name} image={product.image} description={product.description} details={product.details} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
